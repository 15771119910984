<template>
  <v-row
    class="align-right"
    justify="center"
  >
    <v-col class="pa-4" cols="12" lg="4" md="6" sm="8" xs="6">
      <v-img
        src="../../../public/rt_logo.png"
      />
      <h2>Zaloguj się do panelu</h2>
      <p class="mb-10">Proszę wpisać swój login i hasło.</p>
      <v-form
        v-model="isFormValid"
        @submit.prevent="login"
      >
        <v-text-field
          v-model="email"
          :autofocus="true"
          :rules="emailRules"
          label="Twój e-mail"
          required
        ></v-text-field>
        <v-text-field
          v-model="password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="passwordRules"
          :type="showPassword ? 'text' : 'password'"
          class="mt-4"
          label="Hasło"
          required
          @click:append="showPassword = !showPassword"
        ></v-text-field>
        <v-row
          class="align-right"
          justify="center"
        >
          <v-col cols="6">
          </v-col>
          <v-col
            class="text-right"
            cols="6"
          >
            <v-btn :to="{name: 'RemindPassword'}" link small text>
              Zapomniałeś hasła?
            </v-btn>
          </v-col>
        </v-row>
        <v-btn
          :loading="loading"
          block
          color="primary"
          large
          type="submit"
        >
          Zaloguj się
        </v-btn>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import {login} from "@/services/authService";
import {email, required} from "@/utils/validationRules";
import {notify} from "@/services/notificationService";

export default {
  name: 'Login',
  data: () => ({
    email: '',
    emailRules: [required, email],

    password: '',
    passwordRules: [required],

    loading: false,
    showPassword: false,
    isFormValid: false
  }),
  methods: {
    async login() {
      try {
        if (this.isFormValid) {
          this.loading = true
          await login(this.email, this.password)
        }
      } catch (e) {
        notify('error', 'Nie udało się zalogować')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
